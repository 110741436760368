import { render, staticRenderFns } from "./CreateAccreditation.vue?vue&type=template&id=7e2da49d&"
import script from "./CreateAccreditation.vue?vue&type=script&lang=js&"
export * from "./CreateAccreditation.vue?vue&type=script&lang=js&"
import style0 from "./CreateAccreditation.vue?vue&type=style&index=0&id=7e2da49d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports