<template>
  <div>
    <router-link class="come-back" to="/admin/accreditation">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование СМИ' : 'Создание СМИ' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createAccreditation" ref="Аccreditation">
      <div>
        <div class="content-sb">
          <p>Мероприятие<span>*</span></p>
          <input required placeholder="Введите название мероприятия" type="text" name="event" v-model="form.event" class="input blue">
        </div>
        <div class="content-sb">
          <p>Дата и время<span>*</span></p>
          <input required  v-model="getDate" ref="datetime" type="datetime-local" name="date" class="input blue">
        </div>
        <div class="content-sb">
          <p>Наименование СМИ</p>
          <input required placeholder="Наименование СМИ" type="text" v-model="form.massMedia" name="massMedia" class="input blue">
        </div>
        <!-- <div class="content-sb">
          <p>Тема мероприятия<span>*</span></p>
          <input required placeholder="Введите тему мероприятия" type="text" name="topic" v-model="form.topic" class="input blue">
        </div> -->
        <div class="content-sb">
          <p>Имя<span>*</span></p>
          <input required placeholder="Введите имя" v-model="form.firstName" type="text" name="firstName" class="input blue">
        </div>
        <div class="content-sb">
          <p>Фамилия<span>*</span></p>
          <input required placeholder="Введите фамилию" type="text" v-model="form.lastName" name="lastName" class="input blue">
        </div>
        <div class="content-sb">
          <p>Отчество</p>
          <input placeholder="Введите отчество" type="text" v-model="form.patronymic" name="patronymic" class="input blue">
        </div>
        <div class="content-sb">
          <p>Телефон<span>*</span></p>
          <input required placeholder="Введите телефон" type="text" name="phone" v-model="form.phone" class="input blue">
        </div>
        <div class="content-sb">
          <p>Email<span>*</span></p>
          <input required placeholder="Введите почту" type="email" name="email" v-model="form.email" class="input blue">
        </div>
        <div class="content-sb">
          <p>Статус заяви<span>*</span></p>
          <select  @change="changeStatus($event.target.value)" ref="resStatus" required class="input blue"  name="status">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите кем утвержден</option>
            <option v-for="item in $store.getters.getStatusAccreditation"
                    :selected="form.status === item.title"
                    :key="`getStatusAccreditation${item.id}`"
                    :value="item.code"
            >{{ item.title }}</option>
          </select>
        </div>
      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/accreditation" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createAccreditation',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailAccreditation : {
        status: null
      }
    }
  },
  methods: {
    createAccreditation () {
      const formData = new FormData(this.$refs.Аccreditation)
      formData.append('topic', null)
      if (this.$route.query.type === 'edit') {
        this.form.status = this.$refs.resStatus.value
        this.form.date = this.$refs.datetime.value
        this.$store.dispatch('editAccreditation', this.form)
      } else {
        this.$store.dispatch('createAccreditation', formData)
      }
    },
    changeStatus (value) {
      this.form.status = value
    }
  },
  computed: {
    getDate () {
      const validDate = this.form.date.split(' ')
      validDate.splice(1, 0, 'T')
      return validDate.join('')
    }
  },
  mounted () {
    this.$store.dispatch('setStatusAccreditation')
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailAccreditation === null) {
      this.$router.push('/admin/accreditation')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
